import {isDevMode, NgModule} from '@angular/core';

import {MsalBroadcastService, MsalModule, MsalRedirectComponent, MsalService} from '@azure/msal-angular';
import {BrowserCacheLocation, InteractionType, PublicClientApplication} from '@azure/msal-browser';

import {AppRoutingModule} from './app-routing.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AppComponent} from './components/app/app.component';
import {HomeComponent} from './components/pages/home/home.component';
import {PageNotFoundComponent} from './components/pages/pageNotFound/pageNotFound.component';
import {LoginComponent} from './components/auth/login/login.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RetryOrFailInterceptor} from './services/httpInterceptors/retryOrFail.interceptor';
import {HeaderInterceptor} from './services/httpInterceptors/header.interceptor';
import {LoadingErrorComponent} from './components/interface/loadingError/loadingError.component';
import {LoadingComponent} from './components/interface/loading/loading.component';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {ForgotPasswordComponent} from './components/auth/forgotPassword/forgotPassword.component';
import {NotLoggedInGuard} from './services/guards/notLoggedIn.guard';
import {ResetPasswordComponent} from './components/auth/resetPassword/resetPassword.component';
import {TermsComponent} from './components/pages/terms/terms.component';
import {PrivacyComponent} from './components/pages/privacy/privacy.component';
import {MatOptionModule, MatRippleModule} from '@angular/material/core';
import {CountriesResolver} from './services/resolvers/countries.resolver';
import {PurchaseComponent} from './components/order/purchase/purchase.component';
import {
    OrganizationRegistrationComponent
} from './components/auth/organizationRegistration/organizationRegistration.component';
import {PaymentCompletedComponent} from './components/order/purchase/paymentCompleted/paymentCompleted.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ContactComponent} from './components/pages/contact/contact.component';
import {MenuComponent} from './components/app/menu/menu.component';
import {PreviousOrderResolver} from './services/resolvers/previousOrder.resolver';
import {RestoreOrderResolver} from './services/resolvers/restoreOrder.resolver';
import {RestoreUserGuard} from './services/guards/restoreUser.guard';
import {MyMatPaginatorIntl} from './services/providers/MyMatPaginatorIntl';
import {MetaDirective} from './directives/meta.directive';
import {FooterComponent} from './components/app/footer/footer.component';
import {MobileMenuComponent} from './components/app/mobileMenu/mobileMenu.component';
import {StudentDetailsResolver} from './services/resolvers/studentDetails.resolver';
import {LoggedInGuard} from './services/guards/loggedIn.guard';
import {MatBadgeModule} from '@angular/material/badge';
import {MyProductResolver} from './services/resolvers/myProduct.resolver';
import {UserProgressResolver} from './services/resolvers/userProgress.resolver';
import {DiscountCodeResolver} from './services/resolvers/discountCode.resolver';
import {CurrenciesResolver} from './services/resolvers/currencies.resolver';
import {DomainCurrencyResolver} from './services/resolvers/domainCurrency.resolver';
import {LanguageMenuComponent} from './components/app/menu/languageMenu/languageMenu.component';
import {UserMenuComponent} from './components/app/menu/userMenu/userMenu.component';
import {CheckoutComponent} from './components/order/purchase/checkout/checkout.component';
import {HomeGuard} from './services/guards/home.guard';
import {ProductsResolver} from './services/resolvers/products.resolver';
import {StudentGuard} from './services/guards/student.guard';
import {SelfJoinComponent} from './components/auth/selfJoin/selfJoin.component';
import {ClassSelfJoinResolver} from './services/resolvers/classSelfJoin.resolver';
import {MatButtonModule} from '@angular/material/button';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatMenuModule} from '@angular/material/menu';
import {AuthFormLayoutComponent} from './components/auth/authFormLayout/authFormLayout.component';
import {FeaturesComponent} from './components/pages/sections/features/features.component';
import {ProductsComponent} from './components/pages/sections/products/products.component';
import {FaqComponent} from './components/pages/sections/faq/faq.component';
import {FaqQuestionComponent} from './components/pages/sections/faq/question/faqQuestion.component';
import {CtaFooterComponent} from './components/pages/sections/ctaFooter/ctaFooter.component';
import {WhyTouchTypingComponent} from './components/pages/sections/whyTouchTyping/whyTouchTyping.component';
import {
    OrganizationFeaturesComponent
} from './components/pages/sections/organizationFeatures/organizationFeatures.component';
import {
    ExtraOrganizationFeaturesComponent
} from './components/pages/sections/organizationFeatures/extraFeatures/extraFeatures.component';
import {HowDoesItWorkComponent} from './components/pages/sections/howDoesItWork/howDoesItWork.component';
import {ForOrganizationsComponent} from './components/pages/forOrganizations/forOrganizations.component';
import {OrganizationTypesResolver} from './services/resolvers/organizationTypes.resolver';
import {SchoolFeaturesComponent} from './components/pages/sections/schoolFeatures/schoolFeatures.component';
import {BusinessFeaturesComponent} from './components/pages/sections/businessFeatures/businessFeatures.component';
import {LogoutComponent} from './components/auth/logout/logout.component';
import {PaymentStatusResolver} from './services/resolvers/paymentStatus.resolver';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {SharedModule} from './shared/shared.module';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AnalyticsService} from './services/analytics.service';
import {ModalsComponent} from './components/app/modals/modals.component';
import {CookieConsentDialogComponent} from './components/app/modals/cookieConsentDialog/cookieConsentDialog.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BookADemoComponent} from './components/pages/forOrganizations/bookADemo/bookADemo.component';
import {QuestionCallComponent} from './components/pages/sections/questionCall/questionCall.component';
import {
    SchoolLessonPlanComponent
} from './components/pages/forOrganizations/schoolLessonPlan/schoolLessonPlan.component';
import {TyperaceComponent} from './components/pages/typerace/typerace.component';
import {SsoButtonsComponent} from './components/auth/ssoButtons/ssoButtons.component';
import {environment} from '../environments/environment';
import {TabbedAuthFormLayoutComponent} from './components/auth/tabbedAuthFormLayout/authFormLayout.component';
import {MatRadioModule} from '@angular/material/radio';
import {ActivateLicenseGuard} from './services/guards/activateLicense.guard';

@NgModule({
    declarations: [
        // Directives
        MetaDirective,

        // Components
        AppComponent,
        HomeComponent,
        PageNotFoundComponent,
        LoginComponent,
        LoadingErrorComponent,
        LoadingComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        TermsComponent,
        PrivacyComponent,
        ForOrganizationsComponent,
        BookADemoComponent,
        QuestionCallComponent,
        PurchaseComponent,
        CheckoutComponent,
        OrganizationRegistrationComponent,
        PaymentCompletedComponent,
        ContactComponent,
        MenuComponent,
        LanguageMenuComponent,
        UserMenuComponent,
        FooterComponent,
        MobileMenuComponent,
        SelfJoinComponent,
        AuthFormLayoutComponent,
        TabbedAuthFormLayoutComponent,
        FeaturesComponent,
        ProductsComponent,
        FaqComponent,
        FaqQuestionComponent,
        CtaFooterComponent,
        WhyTouchTypingComponent,
        OrganizationFeaturesComponent,
        ExtraOrganizationFeaturesComponent,
        HowDoesItWorkComponent,
        SchoolFeaturesComponent,
        BusinessFeaturesComponent,
        LogoutComponent,
        ModalsComponent,
        CookieConsentDialogComponent,
        SchoolLessonPlanComponent,
        TyperaceComponent,
        SsoButtonsComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        BrowserAnimationsModule,
        NgOptimizedImage,

        // Material modules
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatIconModule,
        MatMenuModule,
        MatSidenavModule,
        MatBadgeModule,
        MatRippleModule,
        MatAutocompleteModule,
        MatSnackBarModule,
        MatCheckboxModule,

        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 10 seconds (whichever comes first).
            /*
            TODO would be better to manually trigger this instead of a timeout
             We could use an Observable factory function for this:
             https://angular.io/api/service-worker/SwRegistrationOptions
             */
            registrationStrategy: 'registerWhenStable:10000'
        }),

        MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
            auth: {
                clientId: environment.microsoft.clientId,
                authority: environment.microsoft.authority,
                redirectUri:
                    (typeof window !== 'undefined') ?
                        window.location.protocol
                        + '//' + window.location.hostname
                        + (window.location.port ? ':' + window.location.port : '')
                        : '',
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: false, // set to true for IE 11
            },
            system: {
                loggerOptions: {
                    loggerCallback: () => {
                    },
                    piiLoggingEnabled: false
                }
            }
        }), {
            interactionType: InteractionType.Popup, // MSAL Guard Configuration
        }, {
            interactionType: InteractionType.Popup, protectedResourceMap: undefined // MSAL Interceptor Configuration
        }),

        MatButtonToggleModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatRadioModule
    ],
    providers: [
        // provideClientHydration(),
        {provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: RetryOrFailInterceptor, multi: true},
        {provide: MatPaginatorIntl, useClass: MyMatPaginatorIntl},

        MsalService,
        MsalBroadcastService,

        // Services
        AnalyticsService,

        // Resolvers
        CountriesResolver,
        MyProductResolver,
        PreviousOrderResolver,
        RestoreOrderResolver,
        StudentDetailsResolver,
        UserProgressResolver,
        DiscountCodeResolver,
        CurrenciesResolver,
        DomainCurrencyResolver,
        ProductsResolver,
        ClassSelfJoinResolver,
        OrganizationTypesResolver,
        PaymentStatusResolver,

        // Factories

        // Guards
        LoggedInGuard,
        NotLoggedInGuard,
        RestoreUserGuard,
        HomeGuard,
        StudentGuard,
        ActivateLicenseGuard
    ],
    bootstrap: [
        AppComponent,
        MsalRedirectComponent // MSAL
    ]
})
export class AppModule {
    constructor(iconRegistry: MatIconRegistry) {
        iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    }
}
