import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    NgZone,
    OnInit,
    Output
} from '@angular/core';
import {catchError, first, switchMap} from 'rxjs/operators';
import {NEVER} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../../shared/services/user.service';
import {NavigationService} from '../../../shared/services/navigation.service';
import {StateService} from '../../../services/state.service';
import {environment} from '../../../../environments/environment';
import {User} from '../../../models/user';
import {MsalService} from '@azure/msal-angular';
import {AuthenticationResult, PopupRequest} from '@azure/msal-browser';
import {OnDestroyMixin} from '@w11k/ngx-componentdestroyed';
import {SsoTypes} from '../../../models/enums/ssoTypes';
import {AppService} from '../../../shared/services/app.service';


declare global {
    interface Window {
        onGoogleSignIn: (response: any) => void;
    }
}

@Component({
    selector: 'course-sso-buttons',
    templateUrl: './ssoButtons.component.html',
    styleUrls: ['./ssoButtons.component.scss'],
})
export class SsoButtonsComponent extends OnDestroyMixin implements OnInit {
    @Input() signUp = false;
    @Output() onSsoAccountCreationRequest: EventEmitter<void> = new EventEmitter();
    googleOAuthClientId: string;
    enableLeerId = false;

    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private userService: UserService,
        private router: Router,
        private snackBar: MatSnackBar,
        public navigationService: NavigationService,
        private stateService: StateService,
        private cd: ChangeDetectorRef,
        private authService: MsalService,
        private appService: AppService,
        private ngZone: NgZone,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        this.initLoginWithGoogle();

        this.enableLeerId = this.activatedRoute.snapshot.data.enableLeerId;
    }

    initLoginWithGoogle() {
        this.googleOAuthClientId = environment.google.oAuth.clientId;

        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(script);

        if (this.appService.isBrowser) {
            window.onGoogleSignIn = this.onGoogleSignIn.bind(this);
        }
    }

    loginWithMicrosoft() {
        this.authService.loginPopup({scopes: ['email', 'profile']} as PopupRequest)
            .pipe(
                catchError(
                    (err) => {
                        switch (err.name) {
                            case 'BrowserAuthError': // f.e. when user closes the popup
                            case 'ServerError': // f.e. when user presses "cancel" when asked for permissions
                                // do nothing
                                break;

                            default:
                                this.showError();
                                this.cd.detectChanges();
                        }
                        return NEVER;
                    }
                ),
                first()
            ).subscribe(
            (response: AuthenticationResult) => {
                return this.onSsoSignIn(response.idToken, SsoTypes.Microsoft);
            }
        );
    }

    onSsoSignIn(jwt: string, type: SsoTypes) {
        this.userService.signInWithSso(jwt, type).pipe(
            first(),
            switchMap(
                (success: boolean) => {
                    return this.userService.getLoggedInUser();
                }
            ),
            catchError(
                (err) => {
                    // no account yet error
                    if (err.error && err.error.message) {
                        switch (err.error.message) {
                            case 'noAccountYet':
                                this.handleNoAccountYet(err.error.data);
                                break;
                        }

                        return NEVER;
                    }

                    // validation errors
                    if (err.status === 422 || (err.status === 400 && err.error.error === 'invalid_grant')) {
                        this.showError();
                        this.cd.detectChanges();
                        return NEVER;
                    }

                    throw err;
                }
            )
        ).subscribe(
            (user) => {
                this.ngZone.run(() => {
                    this.cd.detectChanges();
                    this.loginCompleted(user);

                    this.router.navigate(['/']);
                });
            }
        );
    }

    onGoogleSignIn(token) {
        this.onSsoSignIn(token.credential, SsoTypes.Google);
    }

    handleNoAccountYet(data) {
        this.stateService.setSsoAccountCreationData({
            firstName: data.first_name,
            name: data.name,
            email: data.email,
            ssoType: data.sso_type,
            token: data.token
        });

        this.onSsoAccountCreationRequest.emit();
    }

    showError() {
        this.snackBar.open(
            $localize`:@@auth.error:Something went wrong, please try again!`,
            '',
            {
                duration: 5000,
                panelClass: ['errorSnackbar']
            }
        );
    }

    loginCompleted(user: User) {
        this.stateService.setCartItems(0);
        this.snackBar.open(
            $localize`:@@auth.welcomeBack:Welcome back` + ', ' + user.firstName + '!',
            '',
            {
                duration: 5000,
                panelClass: ['infoSnackbar']
            }
        );
    }

    protected readonly environment = environment;
}
