import {NgModule} from '@angular/core';
import {RouterModule, Routes, UrlMatchResult, UrlSegment} from '@angular/router';
import {HomeComponent} from './components/pages/home/home.component';
import {PageNotFoundComponent} from './components/pages/pageNotFound/pageNotFound.component';
import {LoginComponent} from './components/auth/login/login.component';
import {ForgotPasswordComponent} from './components/auth/forgotPassword/forgotPassword.component';
import {NotLoggedInGuard} from './services/guards/notLoggedIn.guard';
import {ResetPasswordComponent} from './components/auth/resetPassword/resetPassword.component';
import {PrivacyComponent} from './components/pages/privacy/privacy.component';
import {TermsComponent} from './components/pages/terms/terms.component';
import {PurchaseComponent} from './components/order/purchase/purchase.component';
import {PaymentCompletedComponent} from './components/order/purchase/paymentCompleted/paymentCompleted.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {CountriesResolver} from './services/resolvers/countries.resolver';
import {PreviousOrderResolver} from './services/resolvers/previousOrder.resolver';
import {RestoreOrderResolver} from './services/resolvers/restoreOrder.resolver';
import {RestoreUserGuard} from './services/guards/restoreUser.guard';
import {LoggedInGuard} from './services/guards/loggedIn.guard';
import {MyProductResolver} from './services/resolvers/myProduct.resolver';
import {DomainCurrencyResolver} from './services/resolvers/domainCurrency.resolver';
import {CheckoutComponent} from './components/order/purchase/checkout/checkout.component';
import {HomeGuard} from './services/guards/home.guard';
import {SelfJoinComponent} from './components/auth/selfJoin/selfJoin.component';
import {ClassSelfJoinResolver} from './services/resolvers/classSelfJoin.resolver';
import {ForOrganizationsComponent} from './components/pages/forOrganizations/forOrganizations.component';
import {
    OrganizationRegistrationComponent
} from './components/auth/organizationRegistration/organizationRegistration.component';
import {OrganizationTypesResolver} from './services/resolvers/organizationTypes.resolver';
import {RouteTranslations} from './models/abstract/routeTranslations';
import {LogoutComponent} from './components/auth/logout/logout.component';
import {PaymentStatusResolver} from './services/resolvers/paymentStatus.resolver';
import {RouteMatcher} from './services/utils/routeMatcher';
import {TyperaceComponent} from './components/pages/typerace/typerace.component';
import {ActivateLicenseGuard} from './services/guards/activateLicense.guard';


// routes
let routes: Routes;
routes = [
    {
        path: '',
        canActivate: [
            RestoreUserGuard
        ],
        resolve: {
            currency: DomainCurrencyResolver
        },
        children: [
            {
                matcher: (segments: UrlSegment[]): UrlMatchResult => {
                    return RouteMatcher.matchFirstSegment(['course', 'progress'], segments, false);
                },
                loadChildren: () => import('./course/course.module').then(m => m.CourseModule)
            },
            {
                matcher: (segments: UrlSegment[]): UrlMatchResult => {
                    return RouteMatcher.matchFirstSegment(['contact'], segments);
                },
                component: ContactComponent,
                data: {
                    title: $localize `:@@nav.contact:Contact us`,
                    disableContentWrapper: true,
                    translatedSegments: [{i: 0, value: RouteTranslations.contact}]
                }
            },
            {
                path: 'login',
                component: LoginComponent,
                canActivate: [NotLoggedInGuard],
                data: {
                    title: $localize `:@@login.login:Log in`,
                    disableContentWrapper: true
                }
            },
            { // TODO test route: remove when done :-)
                path: 'login/leerid',
                component: LoginComponent,
                canActivate: [NotLoggedInGuard],
                data: {
                    title: $localize `:@@login.login:Log in`,
                    disableContentWrapper: true,
                    enableLeerId: true
                }
            },
            {
                path: 'logout',
                component: LogoutComponent,
                canActivate: [LoggedInGuard],
                data: {
                    title: $localize `:@@auth.logout:Log out`
                }
            },
            {
                matcher: (segments: UrlSegment[]): UrlMatchResult => {
                    return RouteMatcher.matchFirstSegment(['forBusinesses'], segments);
                },
                component: ForOrganizationsComponent,
                data: {
                    title: $localize `:@@website.business.meta.title:Typing course for companies`,
                    description: $localize `:@@website.business.meta.description:The fastest and most fun typing course for your employees. Discounts for companies!`,
                    ctaFooter: 'business',
                    disableContentWrapper: true,
                    isSchool: false,
                    translatedSegments: [{i: 0, value: RouteTranslations.forBusinesses}]
                }
            },
            {
                matcher: (segments: UrlSegment[]): UrlMatchResult => {
                    return RouteMatcher.matchFirstSegment(['forSchools'], segments);
                },
                component: ForOrganizationsComponent,
                data: {
                    title: $localize `:@@website.school.meta.title:Typing course for schools`,
                    description: $localize `:@@website.school.meta.description:The best typing course for students of your school. Suitable for primary and secondary schools. Free for teachers, discounted for students!`,
                    ctaFooter: 'school',
                    disableContentWrapper: true,
                    isSchool: true,
                    translatedSegments: [{i: 0, value: RouteTranslations.forSchools}]
                }
            },
            {
                path: 'school-registration',
                component: OrganizationRegistrationComponent,
                resolve: {
                    countries: CountriesResolver,
                    organizationTypes: OrganizationTypesResolver
                },
                data: {
                    title: $localize `:@@website.school.meta.registrationTitle:Get started with Tyyp for schools`,
                    description: $localize `:@@website.school.meta.registrationDescription:Register your school for the best typing course. Suitable for primary and secondary schools. Get immediate access to the free tracking platform for schools!`,
                    checkoutLayout: true,
                    isSchool: true
                }
            },
            {
                path: 'business-registration',
                component: OrganizationRegistrationComponent,
                resolve: {
                    countries: CountriesResolver,
                    organizationTypes: OrganizationTypesResolver
                },
                data: {
                    title: $localize `:@@website.business.meta.registrationTitle:Get started with Tyyp for businesses`,
                    description: $localize `:@@website.business.meta.registrationDescription:Register your company for the best typing course. Get instant access to the free tracking platform for companies!`,
                    checkoutLayout: true,
                    isSchool: false
                }
            },
            {
                matcher: (segments: UrlSegment[]): UrlMatchResult => {
                    return RouteMatcher.matchFirstSegment(['purchase'], segments);
                },
                component: PurchaseComponent,
                data: {
                    title: $localize `:@@website.shop.meta.title:Order the typing course`,
                    description: $localize `:@@website.shop.meta.description:Get immediate access to the best typing course with hundreds of exercises, typing games and fascinating how-to videos.`,
                    structuredData: ['Product'],
                    ctaFooter: 'purchase',
                    disableContentWrapper: true,
                    translatedSegments: [{i: 0, value: RouteTranslations.purchase}]
                }
            },
            {
                path: 'checkout/upgrade',
                component: CheckoutComponent,
                resolve: {
                    products: MyProductResolver,
                    countries: CountriesResolver,
                    previousOrder: PreviousOrderResolver
                },
                data: {
                    title: $localize `:@@purchase.purchaseALicense:Purchase Tyyp`,
                    checkoutLayout: true,
                    isUpgrade: true
                }
            },
            {
                path: 'checkout',
                component: CheckoutComponent,
                resolve: {
                    products: MyProductResolver,
                    countries: CountriesResolver,
                    previousOrder: PreviousOrderResolver
                },
                data: {
                    title: $localize `:@@purchase.purchaseALicense:Purchase Tyyp`,
                    checkoutLayout: true
                }
            },
            {
                path: 'cart/:id/:code',
                component: CheckoutComponent,
                resolve: {
                    products: MyProductResolver,
                    countries: CountriesResolver,
                    restoreOrder: RestoreOrderResolver
                },
                data: {
                    title: $localize `:@@purchase.purchaseALicense:Purchase Tyyp`,
                    checkoutLayout: true
                }
            },
            {
                path: 'account',
                canActivate: [LoggedInGuard],
                loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
            },
            {
                path: 'sales',
                canActivate: [LoggedInGuard],
                loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule)
            },
            {
                matcher: (segments: UrlSegment[]): UrlMatchResult => {
                    return RouteMatcher.matchFirstSegment(['organization', 'admin'], segments, false, false);
                },
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
            },
            {
                path: 'payment-completed/:id/:code',
                component: PaymentCompletedComponent,
                resolve: {
                    isPaid: PaymentStatusResolver,
                    order: RestoreOrderResolver
                },
                data: {
                    title: $localize `:@@purchase.paymentCompleteTitle:Thank you for your order!`,
                    disableContentWrapper: true
                }
            },
            {
                path: 'payment-completed', // For orders without payment (free coupon)
                component: PaymentCompletedComponent,
                resolve: {
                    order: RestoreOrderResolver
                },
                data: {
                    title: $localize `:@@purchase.paymentCompleteTitle:Thank you for your order!`,
                    disableContentWrapper: true,
                    isPaid: true
                }
            },
            {
                path: 'join/:selfJoinCode',
                component: SelfJoinComponent,
                canActivate: [NotLoggedInGuard],
                resolve: {
                    class: ClassSelfJoinResolver
                },
                data: {
                    title: $localize `:@@selfJoin.joinClass:Join the class` + ' "{{class.name}}"',
                    checkoutLayout: true,
                }
            },
            {
                path: 'join',
                component: SelfJoinComponent,
                canActivate: [NotLoggedInGuard],
                data: {
                    title: $localize `:@@selfJoin.join:Create your account`,
                    checkoutLayout: true,
                }
            },
            {
                path: 'activate-license',
                component: SelfJoinComponent,
                canActivate: [ActivateLicenseGuard],
                data: {
                    title: $localize `:@@organization.activateLicense:Activate license`,
                    checkoutLayout: true,
                    hideClassForm: true
                }
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
                canActivate: [NotLoggedInGuard],
                data: {
                    title: $localize `:@@forgotPassword.forgotYourPassword:Forgot your password?`,
                    disableContentWrapper: true
                }
            },
            {
                path: 'reset-password/:userId/:code',
                component: ResetPasswordComponent,
                canActivate: [NotLoggedInGuard],
                data: {
                    title: $localize `:@@forgotPassword.resetYourPassword:Choose a new password`,
                    disableContentWrapper: true
                }
            },
            {
                path: 'terms',
                component: TermsComponent,
                data: {
                    title: $localize `:@@termsOfService:Terms of Service`
                }
            },
            {
                path: 'privacy',
                component: PrivacyComponent,
                data: {
                    title: $localize `:@@privacyPolicy:Privacy Policy`
                }
            },
            {
                path: 'typerace',
                component: TyperaceComponent,
                data: {
                    title: 'TypeRace'
                }
            },
            {
                path: 'support',
                loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
            },
            {
                path: '',
                component: HomeComponent,
                canActivate: [HomeGuard],
                data: {
                    title: $localize `:@@website.home.meta.title:Learn to type in the fastest and most fun way`,
                    description: $localize `:@@website.home.meta.description:Learn to type with fun exercises, typing games and instructional videos. The fastest and most fun typing course!`,
                    structuredData: ['WebSite', 'Organization'],
                    disableContentWrapper: true,
                    ctaFooter: 'home'
                }
            },
            {
                path: '**',
                component: PageNotFoundComponent,
                data: {
                    title: $localize `:@@pageNotFound.title:Page not found`
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
